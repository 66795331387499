import React from 'react'
import { Link } from 'gatsby'
import { Headline, Button, Theme } from '@liquid-design/liquid-design-react'
import LayoutDefault from '../layouts/LayoutDefault/LayoutDefault'
import TopBar from '../components/TopBar/TopBar'
import Sidebar from '../components/Sidebar/Sidebar'
import Container from '../components/Container/Container'
import MailIconComponent from 'assets/svgs/mail.svg'
import BackIconComponent from 'assets/svgs/back.svg'

export default function About() {
  const MailIcon = MailIconComponent as any
  const BackIcon = BackIconComponent as any

  return (
    <LayoutDefault topBar={<TopBar />} sidebar={<Sidebar />}>
      <Container className="flex flex-col justify-between text-rich-black-lighter">
        <div className="max-w-lg">
          <Theme themeName="vibrantCyan">
            <Link to={'/database/'}>
              <Button
                size="small"
                className="mb-10"
                style={{ minHeight: '2rem' }}
              >
                <span className="flex items-center">
                  <BackIcon className="mr-3" />
                  Back
                </span>
              </Button>
            </Link>
          </Theme>
          <Headline type="H1" className="pt-2 pb-10 text-black">
            About KnoraGene
          </Headline>
          <p className="mb-6">
            This alpha version of KnoraGenes enables you to extract gene
            entitites from any scientific publication.
          </p>
          <p className="mb-6">
            By simply uploading PDFs or entering PubMed Identifiers, the
            conversion algorithm recognizes the gene mentionings and lists them
            up in a nice and tidy way. Based on your feedback, we will develop
            further functionalities in the future.
          </p>
          <p className="mb-6">
            We are looking forward to your feedback. Please contact us in case
            of questions.
          </p>
          <p className="mb-12">
            <b>Thank you for trying out KnoraGene!</b>
          </p>
          <Button size="big" className="mb-10">
            <span className="flex items-center">
              <MailIcon className="mr-3" />
              Give us feedback
            </span>
          </Button>
        </div>
        <div className="flex flex-wrap justify-between">
          <p className="mb-3">
            Provided to you by Merck Digital of Merck KGaA, Darmstadt, Germany
          </p>
          {process.env.REACT_APP_VERSION && (
            <p className="uppercase font-bold mb-3">
              Version&nbsp;{process.env.REACT_APP_VERSION}
            </p>
          )}
        </div>
      </Container>
    </LayoutDefault>
  )
}
